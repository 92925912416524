* {
  margin: 0;
  padding: 0;
}

/* TABLET-LANDSCAPE   */
@media (max-width: 1200px) {
  html {
    font-size: 90%;
  }
}

/* TABLET-PORTRAIT */
@media (max-width: 900px) {
  html {
    font-size: 85%;
  }
}

/* PHONE */
@media (max-width: 600px) {
  html {
    font-size: 75%;
  }
}

#topimagerow {
  --bs-gutter-x: 0px;
  width: calc(100vw);
  padding: 0px;
}

.rmdp-input {
  all: initial;
  box-shadow: 0 0 1px #000;
}
